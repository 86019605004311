@if (company) {
	<ng-container class="c-company-info-card">
		<div class="c-company-info-card__header">
			@if (showTitle) {
				<h3 class="c-vloket-header c-vloket-header--h4">
					<span class="c-vloket-text-marking c-vloket-text-marking--underline">
						{{ title | translate }}
					</span>
				</h3>
			}
			@if (!hideText) {
				<div class="u-margin-bottom c-vloket-text--light">
					<span class="c-company-info-card__non-editable-notice-icon">
						<cjm-img src="img/associations/ic_NoEdit.svg" />
					</span>
					<span>
						<p [innerHtml]="i18nKeys.CheckYourData | translate: { number: company.number } | safeHtml"></p>
					</span>
				</div>
			}
		</div>
		<div class="c-company-info-card__content">
			@if (company.names?.public) {
				<cjm-input-wrapper
					class="c-company-info-card__content__fullName"
					id="RegisteredName"
					[label]="i18nKeys.RegisteredName | translate"
				>
					<input #input type="text" disabled [value]="company.names.public" />
				</cjm-input-wrapper>
			}
			@if (company.names?.short) {
				<cjm-input-wrapper
					class="c-company-info-card__content__shortName"
					id="ShortName"
					[label]="i18nKeys.ShortName | translate"
				>
					<input #input type="text" disabled [value]="company.names.short" />
				</cjm-input-wrapper>
			}
			@if (company.form) {
				<cjm-input-wrapper
					class="c-company-info-card__content__form"
					id="Form"
					[label]="i18nKeys.Form | translate"
				>
					<input #input type="text" disabled [value]="company.form" />
				</cjm-input-wrapper>
			}
			@if (company.number) {
				<cjm-input-wrapper
					class="c-company-info-card__content__number"
					id="Number"
					[label]="i18nKeys.Number | translate"
				>
					<input #input type="text" disabled [value]="company.number | btw" />
				</cjm-input-wrapper>
			}
			@if (company.startDate) {
				<cjm-input-wrapper
					class="c-company-info-card__content__startDate"
					id="StartDate"
					[label]="i18nKeys.StartDate | translate"
				>
					<input #input type="text" disabled [value]="company.startDate | date: 'dd/MM/yyyy'" />
				</cjm-input-wrapper>
			}
			@if (company.registeredOffice) {
				<cjm-input-wrapper
					class="c-company-info-card__content__registeredOffice"
					id="RegisteredOffice"
					[label]="i18nKeys.RegisteredOffice | translate"
					[preventSpacing]="true"
				>
					<input #input type="text" disabled [value]="company.registeredOffice | oneLineAddress" />
				</cjm-input-wrapper>
			}
		</div>
	</ng-container>
}
